<template>
    <div v-if="!product.is_special && product.shop_availability && product.quantity_prices.length > 1"
         class="d-inline-block position-relative"
         @mouseenter="showProductQuantityPrices"
         @mouseleave="scheduleHideProductQuantityPrices">

        <div class="d-flex align-items-center justify-content-center" :class="{'text-success': hasQuantityPriceApplied}">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tags-fill" viewBox="0 0 16 16">
                <path d="M2 2a1 1 0 0 1 1-1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 2 6.586zm3.5 4a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"/>
                <path d="M1.293 7.793A1 1 0 0 1 1 7.086V2a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l.043-.043z"/>
            </svg>
        </div>

        <Teleport to="body" v-if="popoverMounted">
            <div ref="popoverRef"
                 class="popover d-none product-quantity-popover"
                 style="max-width: none;"
                 @mouseenter="cancelHideProductQuantityPrices"
                 @mouseleave="scheduleHideProductQuantityPrices">
                <table class="table mb-0 w-100">
                    <thead class="fw-bold">
                    <tr>
                        <th></th>
                        <th>{{ product.vat_status }}</th>
                        <th v-if="product.vat_percent > 0">{{ $t('Net') }}</th>
                        <th v-if="showPremium && product.premium_percent">{{ $t('Premium') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(price, i) in product.quantity_prices" :key="i">
                        <td :class="{'bg-success': activeQuantityPrice === price.quantity && hasQuantityPriceApplied}">{{ price.quantity }}+</td>
                        <td :class="{'bg-success': activeQuantityPrice === price.quantity && hasQuantityPriceApplied}">{{ formatPrice(price.gross_price) }}</td>
                        <td v-if="product.vat_percent > 0" :class="{'bg-success': activeQuantityPrice === price.quantity && hasQuantityPriceApplied}">{{ formatPrice(price.net_price) }}</td>
                        <td v-if="showPremium && price.premium_percent" :class="{'bg-success': activeQuantityPrice === price.quantity && hasQuantityPriceApplied}">
                            <span class="me-1">{{ $formatter.price(price.premium_value) }}</span>
                            <span>({{ $formatter.percent(price.premium_percent) }}%)</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </Teleport>
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, getCurrentInstance, watch, nextTick } from 'vue';
import { createPopper } from '@popperjs/core';
import { useProductQuantityStore } from "../../stores/ProductQuantityStore.js";

const props = defineProps({
    product: Object,
    addCartQuantity: {
        type: Boolean,
        default: false,
    },
    showPremium: {
        type: Boolean,
        default: true,
    },
});

const popper = ref(null);
const popoverRef = ref(null);
let hideTimeout = null;
const popoverMounted = ref(false);

const instance = getCurrentInstance();
const activeQuantityPrice = ref(null);
const hasQuantityPriceApplied = ref(false);

const quantityStore = useProductQuantityStore();

quantityStore.setQuantity(props.product.id, props.product.quantity);

watch(() => quantityStore.quantities[props.product.id], (newQuantity) => {
    const price = quantityStore.getPriceBasedOnQuantity(props.product, newQuantity, props.addCartQuantity);

    if (!price) {
        return;
    }

    activeQuantityPrice.value = price.quantity;
    hasQuantityPriceApplied.value = activeQuantityPrice.value > 1;
}, {
    immediate: true
});

const formatPrice = (price) => {
    return instance.appContext.config.globalProperties.$formatter.price(price);
};

const showProductQuantityPrices = () => {
    clearTimeout(hideTimeout);
    nextTick(() => {
        if (popoverRef.value) {
            popoverRef.value.classList.remove('d-none');
        }
    });
};

const hideProductQuantityPrices = () => {
    nextTick(() => {
        if (popoverRef.value) {
            popoverRef.value.classList.add('d-none');
        }
    });
};

const scheduleHideProductQuantityPrices = () => {
    hideTimeout = setTimeout(() => {
        hideProductQuantityPrices();
    }, 100);
};

const cancelHideProductQuantityPrices = () => {
    clearTimeout(hideTimeout);
};

onMounted(() => {
    popoverMounted.value = true;

    nextTick(() => {
        if (props.product.quantity_prices.length <= 1 || !popoverRef.value) {
            return;
        }

        popper.value = createPopper(instance.vnode.el, popoverRef.value, {
            placement: 'right',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 8],
                    },
                },
            ],
        });
    });
});

onBeforeUnmount(() => {
    if (popper.value && Object.values(popper.value).length > 0) {
        popper.value.destroy();
    }
    popoverMounted.value = false;
});
</script>
