<template>
    <template v-if="product.shop_availability">
        <small v-if="product.limited_mintage" class="text-warning">
            {{ $t('models.ProductStock.limited_edition_items_left', {value:product.availability.quantity, count:product.availability.quantity}) }}
        </small>
        <small v-else-if="product.is_nos" class="text-success">
            <template v-if="product.nos_days > 0 && product.availability.quantity > 0">
                {{ $t('models.ProductStock.items_left', {value:product.availability.quantity > 0 ? product.availability.quantity : '', count:product.availability.quantity}) }}, {{ $t('models.ProductStock.more_arriving_in', {value:product.nos_days, count:product.nos_days}) }}
            </template>
            <template v-else-if="product.availability.quantity > 0">
                {{ $t('models.ProductStock.items_left', {value:product.availability.quantity > 0 ? product.availability.quantity : '', count:product.availability.quantity}) }}
            </template>
            <template v-else>
                {{ $t('models.ProductStock.on_order') }}
            </template>
        </small>
        <small v-else-if="product.availability.quantity === 1" class="text-warning">
            {{ $t('models.ProductStock.last_item_left') }}
        </small>
        <small v-else-if="product.availability.quantity > 1" class="text-success">
            {{ $t('models.ProductStock.items_left', {value:product.availability.quantity, count:product.availability.quantity}) }}
        </small>
        <small v-else class="text-danger">
            {{ $t('models.ProductStock.out_of_stock') }}
        </small>
    </template>
    <template v-else>
        <small class="text-danger">
            {{ $t('models.ProductStock.out_of_stock') }}
        </small>
    </template>
</template>

<script>
export default {
    props: [
        'product'
    ]
};
</script>
