<template>
    <Modal ref="modalRef" :id="modalName" modal-dialog-class="modal-dialog-centered" modal-content-class="p-4" :show-footer="true" :show-header="false">
        <template #body>
            <TextField id="email" label="Email address" type="email" :model="formData.email" @update:model="formData.email = $event"/>
        </template>

        <template #footer>
            <div class="modal-footer border-top-0 justify-content-center">
                <button type="submit" class="btn btn-primary w-100" @click="sendNotification">{{ $t('Notify me when back in stock') }}</button>
            </div>
        </template>
    </Modal>
</template>

<script setup>
import { ref, computed } from 'vue';
import Modal from "./Modal.vue";
import TextField from "../Fields/Fields/TextField.vue";
import { useAlertStore } from "../../stores/AlertStore.js";
import {useModal} from "../../stores/ModalStore.js";
import api from '../../apis/products.js';
import {useUserStore} from "../../stores/UserStore.js";

const props = defineProps({
    observer: Object,
    product_id: Number,
});

const formData = ref({
    email: useUserStore().user?.email,
});

const modalName = computed(() => 'ProductNotifyModal');

const modalRef = ref(null);

const sendNotification = async () => {
    await api.notify(props.product_id, formData.value.email);
    useModal().closeModal(modalName.value);
    useAlertStore()
        .success('Don’t Go Far!', 'We will notify you via e-mail as soon as the product is back in stock.');

};
</script>
