import { defineStore } from 'pinia'
import {useCartStore} from "./CartStore.js";
import {ref} from 'vue';


export const useProductQuantityStore = defineStore('product_quantity_store', () => {
    const quantities = ref({});

    const setQuantity = function (product_id, quantity) {
        quantities.value[product_id] = quantity;
    }

    const updateQuantity = function (product_id, newQuantity) {
        this.setQuantity(product_id, quantities.value[product_id] +  newQuantity);
    }

    function getPriceBasedOnQuantity(product, quantity, addCartQuantity = true) {
        const quantityPrices = product.quantity_prices;

        if (quantityPrices.length <= 0) {
            return;
        }

        let foundQuantity = quantity || 0;

        if (addCartQuantity) {
            foundQuantity += useCartStore().getProductQuantity(product.id);
        }

        return quantityPrices
            .slice()
            .reverse()
            .find(price => price.quantity <= foundQuantity);
    }

    return {
        quantities,
        setQuantity,
        updateQuantity,
        getPriceBasedOnQuantity
    };
});
